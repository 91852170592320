import React, { FC } from 'react';
import { MarkdownContent } from '@/components/markdown-content';
import { Box, Container, Grid } from '@mui/material';
import { AboutUsSection4 } from '../../../../api/about-us';
import { MainColumn } from '@/components/main-column';
import getGatsbyImageData from '@/utils/getGatsbyImageData';
import { StyledImage } from '@/components/sections/about-us/AboutUs.style';

type IAboutUsSection4 = {
  strapiSection: AboutUsSection4;
};

export const Section4: FC<IAboutUsSection4> = ({ strapiSection }) => {
  const imageData = getGatsbyImageData(strapiSection?.image?.data);
  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        position: 'relative',
        zIndex: 1,
        backgroundColor: 'greyscale.cultured',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          width: { xs: '100%', md: '50vw' },
          height: '100%',
          backgroundColor: 'greyscale.white',
          zIndex: -1,
        }}
      />
      <MainColumn>
        <Box
          sx={{
            pl: { lg: 10 },
          }}
        >
          <Grid
            container
            sx={{
              alignItems: 'center',
              backgroundColor: 'greyscale.white',
              pl: { md: 7, lg: 10 },
              py: { xs: 8, sm: 5, md: 10 },
              pb: { md: 9 },
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={7}
              sx={{
                pr: { md: 10 },
              }}
            >
              <MarkdownContent
                content={strapiSection?.paragraph}
                sx={{
                  mb: 4,
                  'h1, h2, h3, h4, h5, h6': {
                    mb: 3,
                  },
                  '> p': {
                    typography: 'body2',
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={5}
              sx={{
                fontSize: 0,
                pl: { md: 3 },
              }}
            >
              {imageData && (
                <StyledImage
                  alt={
                    strapiSection?.image?.data?.attributes.alternativeText || ''
                  }
                  image={imageData}
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </MainColumn>
    </Container>
  );
};
