import React, { FC } from 'react';
import { Container } from '@mui/material';
import { AboutUsSection2 } from '../../../../api/about-us';
import { MainColumn } from '@/components/main-column';
import { MarkdownContent } from '@/components/markdown-content';

type IAboutUsSection2 = {
  strapiSection: AboutUsSection2;
};

export const Section2: FC<IAboutUsSection2> = ({ strapiSection }) => (
  <Container
    maxWidth={false}
    disableGutters
    sx={{
      backgroundColor: 'greyscale.white',
    }}
  >
    <MainColumn
      sx={{
        pt: { xs: 8, sm: 10 },
        px: { sm: 9, md: 11, lg: 12 },
        mb: { xs: 5, sm: 9 },
      }}
    >
      <MarkdownContent
        content={strapiSection?.paragraph}
        sx={{
          textAlign: 'center',
          'h1, h2, h3, h4, h5, h6': {
            mb: 4,
          },
        }}
      />
    </MainColumn>
  </Container>
);
