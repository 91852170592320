import React, { FC } from 'react';
import { Container, Grid, Box, useTheme, useMediaQuery, SxProps, Typography } from '@mui/material';
import { Masonry } from '@mui/lab';
import { Parallax } from 'react-scroll-parallax';
import { ParallaxProps } from 'react-scroll-parallax/dist/components/Parallax/types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { AboutUsSection1 } from '../../../../api/about-us';
import { MainColumn } from '@/components/main-column';
import { MarkdownContent } from '@/components/markdown-content';
import { computedMainColumnGutters } from '@/gatsby-theme-material-ui-top-layout/spacing';
import getGatsbyImageData from '@/utils/getGatsbyImageData';

type IAboutUsSection1 = {
  strapiSection: AboutUsSection1;
};

export const Section1: FC<IAboutUsSection1> = ({ strapiSection }) => {
  const theme = useTheme();
  const { spacing } = theme;
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));

  const imageProps: {
    style: SxProps,
    parallax: ParallaxProps
  }[] = [
    {
      style: { pr: 1, pl: 2 },
      parallax: { translateY: [spacing(6), spacing(8)] },
    },
    {
      style: { pr: 1, pl: 2 },
      parallax: { translateY: [spacing(10), spacing(6)] },
    },
    {
      style: { pl: 1 },
      parallax: { translateY: [spacing(6), spacing(4)] },
    },
    {
      style: { pl: 6 },
      parallax: { translateY: ['0px', spacing(3)] },
    },
    {
      style: { pl: 4 },
      parallax: { translateY: [spacing(12), spacing(9)] },
    },
    {
      style: { pr: 4 },
      parallax: { translateY: [`${parseInt(spacing(6)) + parseInt(spacing(4))}px`, spacing(12)] },
    },
    {
      style: { pr: 1 },
      parallax: { translateY: [`${parseInt(spacing(10)) + parseInt(spacing(4))}px`, spacing(2)] },
    },
    {
      style: { pl: 5 },
      parallax: { translateY: [`${parseInt(spacing(6)) + parseInt(spacing(6))}px`, spacing(11)] },
    },
    {
      style: { pr: 2, pl: 4 },
      parallax: { translateY: [spacing(5), spacing(6)] },
    },
    {
      style: { pl: 6 },
      parallax: { translateY: [`${parseInt(spacing(12)) + parseInt(spacing(5))}px`, `-${spacing(7)}`] },
    },
  ];

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        backgroundColor: 'greyscale.cultured',
      }}
    >
      <MainColumn
        sx={{
          pt: { xs: 3, md: 11 },
          mb: { xs: 3, sm: 7 },
        }}
      >
        <Grid
          container
          xs={12}
          sx={{
            maxWidth: '1440px',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={7}
          >
            <MarkdownContent
              content={strapiSection?.paragraph}
              sx={{
                fontWeight: 'semiBold',
                marginBlockStart: '2rem',
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={5}
            sx={{
              mb: { xs: 2, md: 0 },
            }}
          >
            <Typography>{strapiSection?.text}</Typography>
          </Grid>
        </Grid>
      </MainColumn>
      <Box
        sx={{
          overflow: { xs: 'hidden', md: 'visible' },
          [theme.breakpoints.down('sm')]: {
            height: '113vw',
            paddingRight: computedMainColumnGutters.xs,
          },
        }}
      >
        <Masonry
          columns={{
            xs: 2,
            sm: 5,
          }}
          spacing={'0px'}
          sx={{
            width: {
              sm: '145%',
              md: '100%',
            },
            margin: '0px',
            flexDirection: {
              xs: 'row',
              sm: 'column',
            },
          }}
        >
          {strapiSection?.images?.data
            ?.filter((image) => !!image)
            ?.map((image, index) => {
              const imageData = getGatsbyImageData(image, {
                layout: 'fullWidth',
              });
              const indexInRow = index %
                (strapiSection?.images?.data?.length / 2);
              const indexInRowOnMobile = index % 2;
              const isFirstRowOnMobile = indexInRow === 0;
              const isSecondRowOnMobile = indexInRow === 1;
              return (
                imageData && imageProps[index] && (
                  <Box
                    key={index}
                    sx={{
                      order: `${indexInRow + 1} !important`,
                      fontSize: 0,
                      [theme.breakpoints.down('sm')]: {
                        ...((isFirstRowOnMobile || isSecondRowOnMobile) && {
                          width: `${
                            indexInRowOnMobile === 0 ? 60 : 40
                          }% !important`,
                        }),
                        alignSelf: isFirstRowOnMobile ? 'flex-end' : 'flex-start',
                      },
                    }}
                  >
                    <Parallax
                      speed={-10}
                      shouldAlwaysCompleteAnimation
                      disabled={isMobile}
                      style={{ transition: '1s ease-out' }}
                      {...imageProps[index].parallax}
                    >
                      <Box
                        sx={{
                          ...imageProps[index].style,
                          [theme.breakpoints.down('sm')]: {
                            paddingTop: '0px',
                            paddingRight: '0px',
                            paddingBottom: 2,
                            paddingLeft: computedMainColumnGutters.xs,
                          },
                        }}
                      >
                        <GatsbyImage
                          key={index}
                          image={imageData}
                          alt={image.attributes?.alternativeText || ''}
                          loading="eager"
                        />
                      </Box>
                    </Parallax>
                  </Box>
                )
              );
            })
          }
        </Masonry>
      </Box>
    </Container>
  );
};
