import React, { FC } from 'react';
import { MarkdownContent } from '@/components/markdown-content';
import { Grid } from '@mui/material';
import { AboutUsSection5 } from '../../../../api/about-us';
import { MainColumn } from '@/components/main-column';

type IAboutUsSection5 = {
  strapiSection: AboutUsSection5;
};

export const Section5: FC<IAboutUsSection5> = ({ strapiSection }) => (
  <Grid
    container
    sx={{
      alignItems: 'center',
      backgroundColor: 'greyscale.cultured',
      mt: { md: -6 },
    }}
  >
    <Grid
      item
      xs={12}
      md={6}
      sx={{
        order: { md: 2 },
        pt: { xs: 8, md: '0px' },
        mt: { md: 6 },
        mb: { xs: 2, md: '0px' },
      }}
    >
      <MainColumn
        halveMaxWidth="md"
        noLeftGutter="md"
      >
        <MarkdownContent
          content={strapiSection?.paragraph}
          sx={{
            pl: { md: 8, lg: 11 },
            'h1, h2, h3, h4, h5, h6': {
              mb: 3,
            },
            '> p': {
              typography: 'body2',
            },
          }}
        />
      </MainColumn>
    </Grid>
    <Grid
      item
      xs={12}
      md={6}
      sx={{
        order: { md: 1 },
        fontSize: '0px',
        zIndex: 1,
      }}
    >
      <video
        preload="auto"
        muted
        autoPlay
        playsInline
        poster={`${strapiSection?.image?.data?.attributes?.url}`}
        style={{
          width: '100%',
        }}
      >
        <source
          src={`${strapiSection?.video?.data?.attributes?.url}`}
          type="video/mp4"
        />
      </video>
    </Grid>
  </Grid>
);
