import React, { FC } from 'react';
import { MarkdownContent } from '@/components/markdown-content';
import { Container, Grid } from '@mui/material';
import { AboutUsSection6 } from '../../../../api/about-us';
import { SectionWithMap } from '@/components/section-with-map';
import { TrackedButton } from '@/components/tracked-button';
import { MainColumn } from '@/components/main-column';

type IAboutUsSection6 = {
  strapiSection: AboutUsSection6;
};

export const Section6: FC<IAboutUsSection6> = ({ strapiSection }) => (
  <>
    <SectionWithMap offices={strapiSection?.offices?.data} />
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        backgroundColor: 'greyscale.persian',
      }}
    >
      <MainColumn>
        <Grid
          sx={{
            py: { xs: 5, md: 8 },
          }}
          container
          flexDirection={{ sm: 'column', md: 'row' }}
          alignItems={'center'}
        >
          <Grid item textAlign={'left'} xs={12} md={9}>
            <MarkdownContent
              content={strapiSection?.paragraph2}
              sx={{
                textAlign: 'left',
                color: 'greyscale.white',
                'h1, h2, h3, h4, h5, h6': {
                  margin: '0px',
                },
              }}
            />
          </Grid>
          <Grid
            item
            md={3}
            xs={12}
            justifyContent={'flex-end'}
            alignItems={'center'}
            display="flex"
            width={'100%'}
          >
            <TrackedButton
              to={strapiSection?.button?.link}
              sx={{
                maxHeight: '56px',
                textAlign: 'center',
                width: '100%',
                maxWidth: { md: '196px', sm: '100%', xs: '100%' },
                mt: { sm: '32px', xs: '32px', md: '0px' },
              }}
              variant={'contained'}
            >
              {strapiSection?.button?.text}
            </TrackedButton>
          </Grid>
        </Grid>
      </MainColumn>
    </Container>
  </>
);
