import React from 'react';
import { StrapiMenu } from '../../api/menu';
import { Menu } from '@/components/menu';
import { StrapiFooter } from '../../api/footer';
import { Footer } from '@/components/footer';
import { Section1 } from '@/components/sections/about-us/Section1';
import { StrapiAboutUs } from '../../api/about-us';
import { Section2 } from '@/components/sections/about-us/Section2';
import { Section3 } from '@/components/sections/about-us/Section3';
import { Section4 } from '@/components/sections/about-us/Section4';
import { Section5 } from '@/components/sections/about-us/Section5';
import { Section6 } from '@/components/sections/about-us/Section6';
import { Head as HeadComponent } from '@/components/head';
import { ParallaxProvider } from 'react-scroll-parallax';
import { Container } from '@mui/material';
import { MENU_HEIGHT } from '@/constants';



export default function AboutUs({
  pageContext: {
    strapiMenu,
    strapiFooter,
    strapiAboutUs: {
      metadata,
      section1,
      section2,
      section3,
      section4,
      section5,
      section6,
    },
  },
}: {
  pageContext: {
    strapiMenu: StrapiMenu;
    strapiFooter: StrapiFooter;
    strapiAboutUs: StrapiAboutUs;
  };
}) {
  const headMeta = {
    title: 'Treeline Interactive',
    meta: {
      MetaTitle: 'Mobile App, Web, and Internet of Things Development Company | Treeline Interactive',
      MetaDescription: 'Treeline Interactive is a custom software and hardware development company, focused on the Internet of Things, Android development, iPad and iPhone development.',
      MetaKeywords: 'mobile app development, mobile application development, android app development san diego, mobile app development san diego, mobile application development san diego, ios developer san diego, ios development san diego',
    },
  };

  return (
    <>
      <HeadComponent
        defaultTitle={headMeta.title}
        defaultMeta={headMeta.meta}
        metadata={metadata}
        image={metadata?.OGImage?.data?.attributes.url
          || strapiMenu.logo.data.attributes.url} />
      <ParallaxProvider>
        <Menu strapiMenu={strapiMenu} />
        <Container
          sx={{
            mt: `${MENU_HEIGHT}px`,
          }}
          maxWidth={false}
          disableGutters
        >
          <Section1 strapiSection={section1} />
          <Section2 strapiSection={section2} />
          <Section3 strapiSection={section3} />
          <Section4 strapiSection={section4} />
          <Section5 strapiSection={section5} />
          <Section6 strapiSection={section6} />
        </Container>
        <Footer strapiFooter={strapiFooter} />
      </ParallaxProvider>
    </>
  );
}
