import React, { FC } from 'react';
import { MarkdownContent } from '@/components/markdown-content';
import { Box, Container, Grid } from '@mui/material';
import { AboutUsSection3 } from '../../../../api/about-us';
import { TrackedButton } from '@/components/tracked-button';
import { MainColumn } from '@/components/main-column';

type IAboutUsSection3 = {
  strapiSection: AboutUsSection3;
};

export const Section3: FC<IAboutUsSection3> = ({ strapiSection }) => (
  <Container
    maxWidth={false}
    disableGutters
    sx={{
      backgroundColor: 'greyscale.cultured',
    }}
  >
    <MainColumn>
      <Grid
        container
        sx={{
          pt: { xs: 8, sm: 10 },
        }}
      >
        <Grid
          item
          xs={12}
          sm={9}
          md={9}
          lg={3.5}
          sx={{
            textAlign: 'left',
            pr: { lg: 4 },
            mb: { xs: 4, md: 8, lg: 9 },
          }}
        >
          <MarkdownContent
            content={strapiSection?.paragraph}
            sx={{
              mr: { md: 4 },
              mb: 4,
              'h1, h2, h3, h4, h5, h6': {
                mb: 3,
              },
              '> p': {
                typography: 'body2',
              },
            }}
          />
          <TrackedButton to={strapiSection?.button?.link} variant={'outlined'}>
            {strapiSection?.button?.text}
          </TrackedButton>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={8.5}
          sx={{
            mb: { xs: 6, md: 10 },
          }}
        >
          <Grid
            container
            spacing={{
              xs: 6,
              md: 4,
            }}
          >
            {strapiSection?.cards?.length &&
              strapiSection?.cards?.map(({ image, paragraph }, idx) => (
                <Grid
                  key={`${image?.data?.attributes?.url}${idx}`}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  sx={{
                    width: '100%',
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      minHeight: {
                        xs: '355px',
                        sm: '690px',
                        md: '400px',
                        lg: '300px',
                      },
                      backgroundImage: `url(${image?.data?.attributes?.url})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                    }}
                  />
                  <MarkdownContent
                    content={paragraph}
                    sx={{
                      mt: 3,
                      '> p': {
                        typography: 'body2',
                        marginBlockStart: '0px',
                        '&:nth-of-type(1)': {
                          mb: 0,
                          '&, & > strong': {
                            typography: 'body1',
                            fontWeight: 'medium',
                          },
                        },
                        '&:nth-of-type(2)': {
                          mb: 0,
                          '&, & > strong': {
                            fontWeight: 'bold',
                          },
                        },
                      },
                    }}
                  />
                </Grid>
              ))
            }
          </Grid>
        </Grid>
      </Grid>
    </MainColumn>
  </Container>
);
